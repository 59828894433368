import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Footer } from 'reactstrap';

import Header from './header'
import './main.scss'
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Container>
          {children}
        </Container>
        <footer className="py-5 mt-5 border-top text-center bg-light">
          <Container>
            <div className="">Niccolò Cantù © {new Date().getFullYear()}</div>
            <div><b>VAT</b> 10639070969</div>
            <div className="small mt-1">Made with <FontAwesomeIcon icon={faReact} size="lg" /></div>
          </Container>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
