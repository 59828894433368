import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Headroom from 'react-headroom';
import { Navbar, NavbarBrand, Container, Nav, NavItem } from 'reactstrap';

import logo from '../images/logo.svg';

import styles from './header.module.scss';

const Header = ({ siteTitle }) => (
  <Headroom>
    <Navbar expand="md" className="py-2 bg-white">
      <Container>
        <div className="text-primary navbar-brand" >
          <Link to="/"><img src={logo} className={styles.logo} /></Link>
        </div>

        <Nav>
          <NavItem className="mx-2"><Link to="/" activeStyle={{ textDecoration: 'underline' }}>Home</Link></NavItem>
          <NavItem className="mx-2"><Link to="/works" activeStyle={{ textDecoration: 'underline' }}>Portfolio</Link></NavItem>
          <NavItem className="mx-2"><Link to="/contact" activeStyle={{ textDecoration: 'underline' }}>Contact Me</Link></NavItem>
        </Nav>
      </Container>
    </Navbar>
  </Headroom>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
